import { mapState } from 'vuex'

import { hpaToInHg } from 'lib/units'

export default {
  methods: {
    formatPressure (hpa) {
      if (this.units == 'imperial') {
        const inHg = hpaToInHg(hpa)
        return `${inHg.toFixed(1)} "Hg`
      } else {
        // Metric and Statute UK
        return `${Math.round(hpa)} hPa`
      }
    }
  },

  computed: {
    ...mapState(['units'])
  }
}
