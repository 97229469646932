<template>
  <l-circle-marker :lat-lng="center" :radius="radius" :color="species.color" :fillColor="species.color" :fillOpacity="1" :weight="0" :pane="pane" @click="$emit('click', sighting)">
    <l-tooltip>
      <p class="m-0">
        <strong>{{ label }}<template v-if="sighting.score"> - </template></strong>
        <small v-if="sighting.score" class="text-muted">Confidence: {{ confidence }}%</small>
      </p>

      <p class="m-0">
        {{ species.commonName }}
        <em class="text-muted">({{ species.scientificName }})</em>
      </p>

      <p class="m-0 small">
        {{ formatDate(sighting.timestamp) }}
        @ {{ formatTime(sighting.timestamp) }}

        ({{ timeSinceSighting.humanize() }} ago)
      </p>
    </l-tooltip>
  </l-circle-marker>
</template>

<script>
import FormatTimeMixin from 'mixins/FormatTime'

import { LCircleMarker, LTooltip } from 'vue2-leaflet'

import moment from 'moment'

export default {
  mixins: [FormatTimeMixin],

  props: {
    label: {
      type: String,
      default: 'BirdNET Sighting'
    },

    sighting: {
      type: Object,
      required: true
    },

    species: {
      type: Object,
      required: true
    },

    pane: {
      type: String,
      default: "mobile"
    },

    radius: {
      type: Number,
      default: 5
    }
  },

  computed: {
    center () {
      return [this.sighting.coords.lat, this.sighting.coords.lon]
    },

    timeSinceSighting () {
      const sightingAt = moment.parseZone(this.sighting.timestamp)
      const now = moment(new Date())
      return moment.duration(now.diff(sightingAt))
    },

    confidence () {
      return Math.round(this.sighting.score * 100)
    }
  },

  components: {
    LCircleMarker,
    LTooltip
  }
}
</script>
