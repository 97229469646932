import Vue from 'vue'
import Vuex from 'vuex'

import { getUserLocale, FAHRENHEIT_LOCALES } from 'lib/units'

Vue.use(Vuex)

function defaultUnitsFromLocale () {
  const locale = getUserLocale()

  if (FAHRENHEIT_LOCALES.includes(locale)) {
    return 'imperial'
  } else {
    return 'metric'
  }
}

export default new Vuex.Store({
  state: {
    locale: localStorage.getItem('locale') || 'en',
    units: localStorage.getItem('units') || defaultUnitsFromLocale(),
    timeFormat: localStorage.getItem('timeFormat') || '12h',
    automaticGain: localStorage.getItem('automaticGain') != 'false',
    liveDetections: localStorage.getItem('liveDetections') != 'false'
  },

  mutations: {
    setLocale (state, locale) {
      state.locale = locale
      localStorage.setItem('locale', locale)
    },

    setUnits (state, units) {
      state.units = units
      localStorage.setItem('units', units)
    },

    setTimeFormat (state, format) {
      state.timeFormat = format
      localStorage.setItem('timeFormat', format)
    },

    setAutomaticGain (state, value) {
      state.automaticGain = value
      localStorage.setItem('automaticGain', value)
    },

    setLiveDetections (state, value) {
      state.liveDetections = value
      localStorage.setItem('liveDetections', value)
    }
  },

  actions: {
    setLocale ({ commit }, locale) {
      commit('setLocale', locale)
    },

    setUnits ({ commit }, units) {
      commit('setUnits', units)
    },

    setTimeFormat ({ commit }, format) {
      commit('setTimeFormat', format)
    },

    setAutomaticGain ({ commit }, value) {
      commit('setAutomaticGain', value)
    },

    setLiveDetections ({ commit }, value) {
      commit('setLiveDetections', value)
    }
  }
})
