<template>
  <div class="soundscape" :class="{ loading: loading }">
    <div class="w-100 position-relative">
      <div v-visible="!loading" class="w-100">
        <Spectrogram ref="spectrogram" :url="soundscape.url" :regions="soundscapeRegions" @loaded="loading = false" @play="playing = true" @pause="playing = false" />

        <div class="soundscape-buttons left" v-if="false">
          <button type="button" class="share-button" @click="shareViaFacebook">
            <font-awesome-icon icon="fab fa-facebook-f" />
          </button>

          <a :href="instagramUrl" target="_blank" class="share-button">
            <font-awesome-icon icon="fab fa-instagram" />
          </a>
        </div>

        <div class="soundscape-buttons right" v-if="detection">
          <VoteButtons :detection="detection" />
          <FlagButton :detection="detection" />
        </div>
      </div>

      <div v-if="loading" class="position-absolute top-0 bottom-0 start-0 end-0 text-muted small d-flex align-items-center justify-content-center">Loading soundscape...</div>
    </div>

    <div class="soundscape-controls">
      <div class="d-flex gap-1 me-2">
        <button v-if="showNavigator" class="btn btn-secondary text-muted" @click="$emit('previous')"><svg style="width: 0.5rem" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-left" class="svg-inline--fa fa-caret-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"></path></svg></button>
        <button class="btn btn-primary text-white" style="width: 4.5em" @click="play" :disabled="loading">{{ playButtonLabel }}</button>
        <button v-if="showNavigator" class="btn btn-secondary text-muted" @click="$emit('next')"><svg style="width: 0.5rem" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" class="svg-inline--fa fa-caret-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg></button>
      </div>

      <p v-if="detection" class="flex-fill small soundscape-detection-label">
        <strong v-if="detection.station">{{ detection.station.name }}</strong><br />
        <span class="text-muted">{{ formatDate(detection.timestamp) }} {{ formatTime(detection.timestamp) }}</span>
      </p>

      <p v-else class="flex-fill small soundscape-detection-label">
        <strong v-if="soundscape.station">{{ soundscape.station.name }}</strong><br />
        <span class="text-muted">{{ formatDate(soundscape.timestamp) }} {{ formatTime(soundscape.timestamp) }}</span>
      </p>

      <DetectionScore v-if="detection" class="flex-shrink-0 ms-2" :detection="detection" />
    </div>
  </div>
</template>

<script>
import DetectionScore from './DetectionScore'
import FlagButton from './FlagButton'
import Spectrogram from './Spectrogram'
import VoteButtons from './VoteButtons'

import FormatTimeMixin from 'mixins/FormatTime'

import { stationType } from '../lib/station'

export default {
  mixins: [FormatTimeMixin],

  props: {
    soundscape: {
      type: Object,
      required: true
    },

    detection: {
      type: Object
    },

    species: {
      type: Object
    },

    showNavigator: {
      type: Boolean,
      default: true
    },

    shareLinks: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: true,
      playing: false
    }
  },

  methods: {
    play () {
      this.$refs.spectrogram.play()
    },

    shareViaFacebook () {
      FB.ui({
        method: 'share',
        href: this.detectionUrl,
      }, () => {})
    }
  },

  computed: {
    soundscapeRegions () {
      if (this.soundscape.startTime && this.soundscape.endTime) {
        const region = { start: this.soundscape.startTime, end: this.soundscape.endTime }

        if (this.species) {
          region.color = this.species.color
        }

        return [region]
      } else {
        return []
      }
    },

    playButtonLabel () {
      return this.playing ? 'Pause' : 'Play'
    },

    stationType () {
      return stationType(this.detection.station.type)
    },

    detectionUrl () {
      return `${document.location.origin}/detections/${this.detection.id}`
    },

    instagramUrl () {
      return `${this.detectionUrl}/instagram.png`
    }
  },

  watch: {
    soundscape () {
      this.loading = true
    }
  },

  components: {
    DetectionScore,
    FlagButton,
    Spectrogram,
    VoteButtons
  }
}
</script>
