<template>
  <component v-bind:is="componentType" :size="size">
    <div class="mb-2">
      <label class="settings-form-label d-none d-xxl-block">Language</label>
      <select v-model="localLocale" class="form-select form-select-sm">
        <option v-for="(name, code) in LOCALES" :value="code" :key="code">{{ name }}</option>
      </select>
    </div>

    <div class="mb-2">
      <label class="settings-form-label d-none d-xxl-block">Units</label>
      <select v-model="localUnits" class="form-select form-select-sm">
        <option value="metric">Metric</option>
        <option value="imperial">Imperial</option>
        <option value="uk">Statute UK</option>
      </select>
    </div>

    <div>
      <label class="settings-form-label d-none d-xxl-block">Time Format</label>
      <select v-model="localTimeFormat" class="form-select form-select-sm">
        <option value="12h">12 hour</option>
        <option value="24h">24 hour</option>
      </select>
    </div>

    <hr class="settings-form-divider" />

    <div>
      <Checkbox v-model="localAutomaticGain">Automatic Gain</Checkbox>
      <Checkbox v-model="localLiveDetections" class="mb-0">Live Detections</Checkbox>
    </div>
  </component>
</template>

<script>
import Checkbox from 'components/Checkbox'
import SettingsDropdown from './SettingsDropdown'

import { mapState, mapActions } from 'vuex'

const LOCALES = {
  'af': 'Afrikaans',
  'ar': 'Arabic',
  'cs': 'Czech',
  'da': 'Danish',
  'de': 'German',
  'en': 'English (US)',
  'en-UK': 'English (UK)',
  'es': 'Spanish',
  'fi': 'Finnish',
  'fr': 'French',
  'hu': 'Hungarian',
  'it': 'Italian',
  'ja': 'Japanese',
  'ko': 'Korean',
  'nl': 'Dutch',
  'no': 'Norwegian',
  'pl': 'Polish',
  'pt-BR': 'Portuguese (Brazil)',
  'pt-PT': 'Portuguese (Portugal)',
  'ro': 'Romanian',
  'ru': 'Russian',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'sv': 'Swedish',
  'th': 'Thai',
  'tr': 'Turkish',
  'uk': 'Ukrainian',
  'zh': 'Chinese'
}

export default {
  props: {
    size: {
      type: String
    },

    as: {
      type: String,
      default: 'auto'
    }
  },

  data () {
    return {
      LOCALES,
      windowWidth: null
    }
  },

  created () {
    window.addEventListener('resize', this.updateWindowSize)
  },

  destroyed () {
    window.removeEventListener('resize', this.updateWindowSize)
  },

  computed: {
    ...mapState(['locale', 'units', 'timeFormat', 'automaticGain', 'liveDetections']),

    componentType () {
      if (this.as == 'dropdown') {
        return SettingsDropdown
      } else if (this.as == 'div') {
        return 'div'
      } else {
        this.updateWindowSize()
        return this.windowWidth >= 1500 ? SettingsDropdown : 'div'
      }
    },

    localLocale: {
      get () { return this.locale },
      set (value) { this.setLocale(value) }
    },

    localUnits: {
      get () { return this.units },
      set (value) { this.setUnits(value) }
    },

    localTimeFormat: {
      get () { return this.timeFormat },
      set (value) { this.setTimeFormat(value) }
    },

    localAutomaticGain: {
      get () { return this.automaticGain },
      set (value) { this.setAutomaticGain(value) }
    },

    localLiveDetections: {
      get () { return this.liveDetections },
      set (value) { this.setLiveDetections(value) }
    }
  },

  methods: {
    ...mapActions(['setLocale', 'setUnits', 'setTimeFormat', 'setAutomaticGain', 'setLiveDetections']),

    updateWindowSize () {
      this.windowWidth = window.innerWidth
    }
  },

  components: {
    Checkbox,
    SettingsDropdown
  }
}
</script>
