import Vue from 'vue'

import moment from 'moment'

Vue.filter('formatTime', function (value, format) {
  if (value) {
    return moment.parseZone(String(value)).format(format || 'h:mm a') + ' (filter)'
  }
})

Vue.filter('formatDate', function (value, format) {
  if (value) {
    return moment.parseZone(String(value)).format(format || 'MM/DD/YYYY') + ' (filter)'
  }
})
