export function getUserLocale () {
  return Intl.NumberFormat().resolvedOptions().locale
}

// Temperature

export const FAHRENHEIT_LOCALES = [
  'en-US',
  'en-MS',
  'en-PW',
  'en-VG',
  'en-TC',
  'en-KN',
  'en-MH',
  'en-BM',
  'en-KY',
  'en-AG',
  'en-FM',
  'en-BS',
  'en-BZ',
  'en-LR'
]

export function formatTemperature (celsius) {
  const locale = getUserLocale()

  if (FAHRENHEIT_LOCALES.includes(locale)) {
    return `${celsiusToFahrenheit(celsius).toFixed(1)}° F`
  } else {
    return `${celsius.toFixed(1)}° C`
  }
}

export function formatTemperatureKelvin (kelvin) {
  const locale = getUserLocale()

  if (FAHRENHEIT_LOCALES.includes(locale)) {
    return `${kelvinToFahrenheit(kelvin).toFixed(1)}° F`
  } else {
    return `${kelvinToCelsius(kelvin).toFixed(1)}° C`
  }
}

export function kelvinToFahrenheit (kelvin) {
  return 1.8 * (kelvin - 273.15) + 32
}

export function kelvinToCelsius (kelvin) {
  return kelvin - 273.15
}

export function celsiusToFahrenheit (celsius) {
  return 1.8 * celsius + 32
}

// Barometric Pressure

export function hpaToInHg (hPa) {
  return hPa * 0.02953
}

// Wind speed

export function formatWindSpeed (mps) {
  return `${metersPerSecondToMilesPerHour(mps).toFixed()} mph`
}

export function metersPerSecondToMilesPerHour (mps) {
  return mps * 2.236936
}

export function metersPerSecondToKilometersPerHour (mps) {
  return mps * 3.6
}
