import moment from 'moment'

import { mapState } from 'vuex'

export default {
  methods: {
    formatTime (value, format) {
      if (value) {
        return moment.parseZone(String(value)).format(format || this.defaultTimeFormat)
      }
    },

    formatDate (value, format) {
      if (value) {
        return moment.parseZone(String(value)).format(format || this.defaultDateFormat)
      }
    }
  },

  computed: {
    ...mapState(['timeFormat']),

    defaultTimeFormat () {
      if (this.timeFormat == '24h') {
        return 'HH:mm'
      } else {
        return 'h:mm a'
      }
    },

    defaultDateFormat () {
      return 'MM/DD/YYYY'
    }
  }
}
