<template>
  <p>
    <template v-if="!weatherData && $apollo.queries.weatherData.loading">
      Loading station weather...
    </template>

    <template v-else-if="weatherData">
      <template v-if="weatherData.weather">
        Currently:
        {{ formatTemperature(kelvinToCelsius(weatherData.weather.temp)) }} ({{ weatherData.weather.description }}) &mdash;
        Wind: {{ formatWindSpeed(weatherData.weather.windSpeed) }} &mdash;
      </template>

      <template v-if="weatherData.airPollution">
        AQI: {{ weatherData.airPollution.aqi }}
      </template>
    </template>
  </p>
</template>

<script>
import FormatTemperatureMixin from 'mixins/FormatTemperature'
import FormatWindSpeedMixin from 'mixins/FormatWindSpeed'

import StationWeatherQuery from 'queries/StationWeather.graphql'

import { kelvinToCelsius } from 'lib/units'

export default {
  mixins: [FormatTemperatureMixin, FormatWindSpeedMixin],

  props: {
    station: {
      type: Object,
      required: true
    },

    autoload: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      skipWeather: !this.autoload,
      weatherData: null
    }
  },

  apollo: {
    weatherData: {
      query: StationWeatherQuery,

      variables () {
        return {
          stationId: this.station.id
        }
      },

      skip () {
        return this.skipWeather
      }
    }
  },

  methods: {
    kelvinToCelsius,

    loadData () {
      this.skipWeather = false
      this.$apollo.queries.weatherData.refetch()
    }
  }
}
</script>
