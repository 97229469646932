import { mapState } from 'vuex'

import { celsiusToFahrenheit } from 'lib/units'

export default {
  methods: {
    formatTemperature (celsius) {
      if (this.units == 'imperial') {
        const fahrenheit = celsiusToFahrenheit(celsius)
        return `${fahrenheit.toFixed(1)}° F`
      } else {
        return `${celsius.toFixed(1)}° C`
      }
    }
  },

  computed: {
    ...mapState(['units'])
  }
}
