import { mapState } from 'vuex'

import { metersPerSecondToMilesPerHour, metersPerSecondToKilometersPerHour } from 'lib/units'

export default {
  methods: {
    formatWindSpeed (mps) {
      if (this.units == 'metric') {
        const kph = metersPerSecondToKilometersPerHour(mps)
        return `${kph.toFixed()} km/h`
      } else {
        // Imperial and Statute UK
        const mph = metersPerSecondToMilesPerHour(mps)
        return `${mph.toFixed()} mph`
      }
    }
  },

  computed: {
    ...mapState(['units'])
  }
}